/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';

/**
 * Internal dependencies.
 */
import WidgetCircle from 'components/widget-circle/widget-circle';
import ModalGenericAlert from 'components/modal-generic-alert/modal-generic-alert';

import { getAppMetadataValue, getProfileData, requireAuthToken, requireUserID } from 'MyUtils.js'


const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '34px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			'&::-webkit-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#858585',
				oapcity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		[theme.breakpoints.down('xs')]: {
			margin: 'auto'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalSubtitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		marginBottom: '11px',
		'& span': {
			fontSize: '12px',
		}
	},
	modalHint: {
		marginTop: '14px',
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		'& span': {
			display: 'block',
			color: '#323338',
			marginBottom: '17px',
		},
		'& i': {
			marginRight: '8px',
		}
	},
	modalActions: {
		margin: '28px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalButtonDisabled: {
		opacity: '.3'
	}
}));

const ModalNew = ( { onClose, modifier } ) => {
	const classes = useStyles();
	const [ openInnerModal, setOpenInnerModal ] = useState(false);
	const [ name, setName ] = useState("");
	const [disabled, setDisabled] = useState(false);

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Nuevo círculo</Typography>
			</Box>

			<Box className={classes.modalBody}>
				<Input placeholder="Nombre" disabled={disabled} className={classes.modalField} id="name-field" onChange={e => setName(e.target.value)}/>

				{/* <Grid container spacing={5} alignItems="center"> // TODO allow for uploading images during creation
					<Grid item md={6} xs={12}>
						<Typography 
							variant="h4" 
							component="h4"
							className={classes.modalSubtitle}
						>Logo <span>(1920x1080px)</span></Typography>

						<WidgetCircle 
							modifier={classes.modalCircle}	
							buttonText="Subir archivo"
							url="/"
						/>

						<Typography 
							variant="h4" 
							component="h4"
							className={classes.modalHint}
						>
							<span>Default</span>

							<i className="ico-image"></i> PNG · 0 KB
						</Typography>
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography 
							variant="h4" 
							component="h4"
							className={classes.modalSubtitle}
						>Póster <span>(2048x1300px)</span></Typography>

						<WidgetCircle 
							modifier={classes.modalCircle}	
							buttonText="Subir archivo"
							url="/"
						/>

						<Typography 
							variant="h4" 
							component="h4"
							className={classes.modalHint}
						>
							<span>Default</span>

							<i className="ico-image"></i> PNG · 0 KB
						</Typography>
					</Grid>
				</Grid> */}
			</Box>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar</Button>
				
				{/* TODO implementar invitar usuarios <Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton + ' ' + classes.modalButtonDisabled}
					onClick={(e) => { setOpenInnerModal(!openInnerModal) }}
				>Invitar usuarios</Button> */}
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton + ' ' + (name == "" ? classes.modalButtonDisabled : "")}
					disabled={name == "" || disabled}
					onClick={async (e) => {
						setDisabled(true);
						try {
							const authToken = await requireAuthToken(false, true);
							const userId = requireUserID(true);

							const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/room`, { //?Creator=${userId}

								// Adding method type
								method: "POST",

								// Adding body or contents to send
								body: JSON.stringify({
									RoomName: document.getElementById("name-field").value,
									GameliftAlias: process.env.REACT_APP_GAMELIFT,
									NumMaxPlayers: 8
								}),

								// Adding headers to the request
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!response.ok) {
								throw new Error(`HTTP error! status: ${response.status}`); // handle errors
							}

							const data = await response.json();

							//console.log(data)

							if ("Error" in data) {
								throw new Error(`Error creating room: ${data.Error.Message}`); // handle errors
							}

							// Append room id to array of rooms metadata
							const profile = await getProfileData(userId);
							let rooms = getAppMetadataValue(profile, "Rooms");
							if (rooms === "") {
								rooms = [];
							}
							rooms.push(data.RoomId);

							// Invite user to room
							const responseInvite = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
								method: "POST",
								body: JSON.stringify({
									App_Metadata: { Rooms: rooms }
								}),
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!responseInvite.ok) {
								throw new Error(`HTTP error! status: ${responseInvite.status}`);
							}

							const dataInvite = await responseInvite.json();

							//console.log(dataInvite);

							if ("Error" in dataInvite) {
								throw new Error(`Error inviting to room: ${dataInvite.Error.Message}`);
							}

							setOpenInnerModal(!openInnerModal)

						} catch (error) {
							setDisabled(false);
							throw new Error(error);
						}
					}}
				>Crear círculo{/* Invitar usuarios */}</Button>
			</Box>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false);  window.location.reload(false);}}
			>
				<div>
					<ModalGenericAlert  onClose={(e) => { setOpenInnerModal(false); window.location.reload(false);}}>
						Círculo creado con <span>éxito</span><br/><br/>
					</ModalGenericAlert>
				</div>
			</Modal>

		</Box>
	);
};

export default ModalNew;