/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';

/**
 * Internal dependencies.
 */
import WidgetEdit from 'components/widget-edit/widget-edit';
import ModalDelete from 'components/modal-delete/modal-delete';

import { AskForFile, fetchMedia, requireAuthToken, requireUserID, uploadMedia } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '34px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			color: '#323338',
			'&::-webkit-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		[theme.breakpoints.down('xs')]: {
			margin: 'auto'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalSubtitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		marginBottom: '11px'
	},
	modalHint: {
		marginTop: '14px',
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		'& span': {
			display: 'block',
			color: '#323338',
			marginBottom: '17px',
		},
		'& i': {
			marginRight: '8px',
		}
	},
	modalActions: {
		margin: '11px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalLink: {
		padding: '0',
		margin: '24px 0 0',
		color: '#00CCEE',
		fontSize: '18px',
		letterSpacing: '0.5px',
	}
}));

const ModalEdit = ({ onClose, modifier, circlename, roomId }) => {
	const classes = useStyles();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [name, setName] = useState("");
	const [posterImage, setPosterImage] = useState(require('assets/images/tunel-small.png'));
	const [logoImage, setLogoImage] = useState(require('assets/images/tunel-small.png'));
	const [reload, setReload] = useState(false);
	

	useEffect(() => {
		async function fetchImages() {
			const collection = `${requireUserID(true)}/${roomId}/Logo`;
			const data = await fetchMedia(collection);

			data.MediaFiles.forEach(element => {
				if (element.FileName === collection + "/LogoScreen.png") {
					setLogoImage(element.DownloadUrl);
				} else if (element.FileName === collection + "/LogoPoster.png") {
					setPosterImage(element.DownloadUrl);
				}
			});
		};

		fetchImages();
	}, [reload]);


	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalTitle}
				>Editar círculo</Typography>
			</Box>

			<Box className={classes.modalBody}>
				<Input defaultValue={circlename} id="name-field" disabled={disabled} className={classes.modalField} onChange={e => setName(e.target.value)}/>

				<Grid container spacing={5} alignItems="center">
					<Grid item md={6} xs={12}>
						<Typography
							variant="h4"
							component="h4"
							className={classes.modalSubtitle}
						>Logo</Typography>

						<WidgetEdit image={logoImage} accept='.png' onChange={(e) => {
							uploadLogo();

							async function uploadLogo() {
								const file = AskForFile(e, false, true);
								if (!file)
									throw new Error("Error getting file");

								const regex = new RegExp('[^A-Za-z0-9.]');
								if (regex.test(file.name)) {
									window.alert("El nombre de fichero no puede contener símbolos, tildes ni espacios.");
									throw new Error("Error getting file: unsupported symbols in filename");
								}
								await uploadMedia(file, `${requireUserID(true)}/${roomId}/Logo`, "LogoScreen.png");
								setReload(!reload);
							}
						}} />

						<Typography
							variant="h4"
							component="h4"
							className={classes.modalHint}
						>
							<i className="ico-image"></i> PNG{/*  · 129 KB */}
						</Typography>
					</Grid>

					<Grid item md={6} xs={12}>
						<Typography
							variant="h4"
							component="h4"
							className={classes.modalSubtitle}
						>Póster</Typography>

						<WidgetEdit image={posterImage} accept='.png' onChange={(e) => {
							uploadPoster();

							async function uploadPoster() {
								const file = AskForFile(e, false, true);
								if (!file)
									throw new Error("Error getting file");

								const regex = new RegExp('[^A-Za-z0-9.]');
								if (regex.test(file.name)) {
									window.alert("El nombre de fichero no puede contener símbolos, tildes ni espacios.");
									throw new Error("Error getting file: unsupported symbols in filename");
								}
	
								await uploadMedia(file, `${requireUserID(true)}/${roomId}/Logo`, "LogoPoster.png");
								setReload(!reload);
							}
						}} />

						<Typography
							variant="h4"
							component="h4"
							className={classes.modalHint}
						>
							<i className="ico-image"></i> PNG{/*  · 234 KB */}
						</Typography>
					</Grid>
				</Grid>

				<Button
					variant="text"
					className={classes.modalLink}
					onClick={(e) => { setOpenDeleteModal(!openDeleteModal) }}
				>Eliminar círculo</Button>
			</Box>

			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar</Button>

				<Button
					variant="contained"
					color="secondary"
					className={classes.modalButton}
					disabled={name == "" || disabled}
					onClick={(e) => {
						setDisabled(true)
						submitChanges();

						async function submitChanges() {
							const authToken = await requireAuthToken(false, true);

							const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/room/edit/${process.env.REACT_APP_GAMELIFT}/${roomId}`, {
								method: "POST",
								body: JSON.stringify({
									RoomName: document.getElementById("name-field").value
								}),
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!response.ok) {
								setDisabled(false);
								throw new Error(`HTTP error! status: ${response.status}`);
							}

							const data = await response.json();

							//console.log(data);

							if ("Error" in data) {
								setDisabled(false);
								throw new Error(`Error editing room: ${data.Error.Message}`);
							}

							window.location.reload();

							onClose();
						}

					}}
				>Guardar</Button>
			</Box>

			<Modal
				open={openDeleteModal}
				onClose={(e) => { setOpenDeleteModal(false) }}
			>
				<div><ModalDelete circlename={circlename} roomId={roomId} onClose={(e) => { setOpenDeleteModal(false) }} /></div>
			</Modal>
		</Box>
	);
};

export default ModalEdit;